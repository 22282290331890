import { Container, Row, Col } from "react-bootstrap";
import Singe from "../assets/images/Banner/4.svg";
import background from "../assets/images/Banner/Banner_MKS.mp4";

const Banner = () => {
  return (
    <section className="banner" id="banner">
      <video autoPlay loop muted className="background-video">
        <source src={background} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center img-container">
            <img src={Singe} alt="banner" height={"250px"} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
